import axios from 'axios'

const debug = process.env.REACT_APP_DEBUG;

export default (error) => {
    if (debug) {
        if (error) {
            if (!axios.isCancel(error)) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return;
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    return
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    return
                }
            }
        }
        else
            console.log("ha ocurrido un error no identificado.")

    }
}