import PropTypes from "prop-types";
import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, FormHelperText } from '@material-ui/core';

import { StyledFormControl } from './styled';
import { createFilterOptions, FilterOptionsState } from '@material-ui/lab';

const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) =>
    defaultFilterOptions(options, state).slice(0, 100);

function Combo({
    label,
    error,
    value,
    options,
    onChange,
    config,
}) {
    
    return (
        <StyledFormControl error={error !== null && error !== undefined} fullWidth>
            <Autocomplete
                autoSelect
                openOnFocus
                value={value}
                options={options}
                getOptionLabel={option => option.name}
                limitTags={50}
                style={{ width: "100%" }}
                noOptionsText="No hay resultados..."
                renderInput={params => <TextField {...params} label={label} />}
                onChange={onChange}
                filterOptions={filterOptions}
                loadingText="Buscando..."
                {...config}
            />

            {
            error
            ? <FormHelperText error={error !== null && error !== undefined}>{error}</FormHelperText>
            : null
            }
        </StyledFormControl>
    )
}

export default React.memo(Combo)
