import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const tileHeight = 150
const server_path = process.env.REACT_APP_SERVER;


const useStyles = makeStyles((theme) => ({
    tile: {
        cursor: "pointer",
    },
    tileImg: {        
        height: tileHeight + "px",
        overflow: "hidden",
    },
    selected: {
        border: "5px solid " + theme.palette.primary.main,
    }
}));

export default function ImageSelector(props) {

    const classes = useStyles();
    return (
        <div style={{maxHeight:"300px", width:"100%", overflowY:"auto", overflowX:"hidden"}}>
            <Grid container direction="column" spacing={2} style={{padding:"10px"}}>
                {
                    props.data.map((tile) => (
                        <Grid key={tile.id} item container justify="space-between" onClick={() => props.onImageSelected(tile)} className={(props.selected && props.selected.id === tile.id ? classes.selected : "") + " " + classes.tile}>
                            <Grid item xs={12} md={6} className={classes.tileImg} >
                                <img src={server_path + tile.image} alt={tile.title} style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"}}/>
                            </Grid>
                            <Grid item xs={12} md={6} style={{paddingLeft:"10px"}}>
                                <Typography>
                                    Title: {tile.title}
                                </Typography>
                                <Typography>
                                    Date: {tile.date}
                                </Typography>
                                <Typography>
                                    Size: {tile.size}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            
            </Grid>
            {/* <GridList cellHeight={180} className={classes.gridList}>                
                {props.data.map((tile) => (
                    <GridListTile key={tile.image} onClick={()=>props.onImageSelected(tile)}>
                        <img src={tile.image} alt={tile.title} />
                        <GridListTileBar
                            title={tile.title}
                            subtitle={<span>by: {tile.size}</span>}
                        />
                    </GridListTile>
                ))}
            </GridList> */}
        </div>
    );
}
