import {Grid} from "@material-ui/core";
import React from "react";
import styled from 'styled-components';

export const Credits = styled(CreditsContainer)`
    z-index:2;
    position: absolute;
    width: 100vw; 
    bottom: 5px;
    flex-direction:row;
    justify-content:space-between;
    padding:0px 10px;       
    & .MuiGrid-item{
        line-height:9px;
        height:12px;
    }  
    & a{
        cursor:pointer;
        color: #ffffff70;
        font-size:9px;
        font-weight:bold;
    }
    ${props=>props.theme.breakpoints.up('sm')}{
        flex-direction:column;
        justify-content:unset;
        padding:unset;
        align-items:flex-end;
        padding-right: 15px;
        & .MuiGrid-item{
            line-height:unset;
            height:unset;
        }  
    }
`

function CreditsContainer({className}) {
    const handleClick=(url)=>{
        window.open(url);
    }
    return <Grid container   className={className}>
        <Grid item>
            <a onClick={()=>handleClick("https://www.areavisual.com.ar/")}>made by Area Visual</a>
        </Grid>
        <Grid item>
            <a onClick={()=>handleClick("mailto:nicolas_quintana_3981@hotmail.com")}>made by Blue Box Software</a>
        </Grid>
    </Grid>;
}