import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PicturesPage from 'pages/PicturesPage'
import RoomsPage from 'pages/RoomsPage'
export const sidebarItems = [
    [
        {
            id: 1,
            label: "Pictures",
            icon: PhotoLibraryIcon,
            isActive: false,
            path: "/admin/pictures",
            exact: true,
            component: PicturesPage

        }, {
            id: 2,
            label: "Floors",
            icon: MeetingRoomIcon,
            isActive: false,
            path: '/admin/floors',
            exact: true,
            component: RoomsPage

        },
    ],
];
