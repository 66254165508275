import printError from 'error'
import axios from 'axios'
import { getConfigs } from 'axiosConfig'
import * as Utils from 'Utils'
import _ from 'lodash'

const apiPath = process.env.REACT_APP_API;

export const RoomsProvider = {
    all : (datatable,cancelToken=null) => {
        return axios.get(apiPath + "rooms", {
            ...getConfigs(),
            cancelToken: cancelToken,
            params: { ...datatable, filters: JSON.stringify(datatable.filters), order: JSON.stringify(datatable.order) }
        }).then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },

    get : (roomId, cancelToken=null) => {
        return axios.get(apiPath + "rooms/" + roomId, {
            ...getConfigs(), cancelToken:cancelToken,
        }).then(res => {
            return Promise.resolve(res.data.room)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },

    post : (_room, cancelToken=null) => {
        const room = _.cloneDeep(_room)
        var formData = new FormData();
        room.template = room.template.id
        room.pictures = room.pictures.map(p => ({ id: p.id, slot_id: p.slot_id }))
        room.points = room.points.map(p => ({ point_id: p.point_id,imageType:p.imageType, image: p.image.name }))

        formData.append("data", JSON.stringify(room))



        if (_room.points.length > 0) {
            for (let i = 0; i < _room.points.length; i++) {
                const point = _room.points[i];
                formData.append("images[]", point.image);
            }
        }

        return axios.post(apiPath + "rooms", formData, {
            ...getConfigs(), cancelToken:cancelToken,
        }).then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },

    put : (_room, cancelToken=null) => {
        const room = _.cloneDeep(_room)
        var formData = new FormData();
        room.template = room.template.id
        room.pictures = room.pictures.map(p => ({ id: p.id, slot_id: p.slot_id }))
        room.points = room.points.filter(p=> !Utils.isString(p.image)).map(p => ({ point_id: p.point_id, image: p.image.name }))

        formData.append("data", JSON.stringify(room))



        if (_room.points.length > 0) {
            for (let i = 0; i < _room.points.length; i++) {
                const point = _room.points[i];
                formData.append("images[]", point.image);
            }
        }

        formData.append('_method', 'PUT');
        return axios.post(apiPath + "rooms/" + room.id, formData, {
            ...getConfigs({
                headers: {
                    'Cache-Control': 'no-cache',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data'
                }
            }), cancelToken:cancelToken,
        }).then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },

    del : (roomId, cancelToken=null) => {
        return axios.delete(apiPath + "rooms/" + roomId, {
            ...getConfigs(), cancelToken:cancelToken,
        }).then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    }
}
