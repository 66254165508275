import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide, DialogContent } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
    const classes = useStyles();

    return (
        <div>
            <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        {
                        (props.options && props.options.showClose) || true
                        ?   <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        : null
                        }
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                        {
                            props.options && props.options.actions ?
                                props.options.actions.map(action => (                                    
                                    <Button key={"action-"+action.label} autoFocus color="inherit" disabled={action.disabled} onClick={action.onClick}>
                                        {action.label}
                                    </Button>
                                ))
                                : null
                        }
                    </Toolbar>
                </AppBar>
                <DialogContent style={{ paddingTop:"20px", overflowY:'overlay'}}>
                    {props.children}
                </DialogContent>
            </Dialog>
        </div>
    );
}

FullScreenDialog.propTypes = {

}


