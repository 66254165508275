import React from 'react'
import PropTypes from 'prop-types'
import { Grid, CircularProgress } from '@material-ui/core'

const CenteredCircularProgress = (
    {label, backdropped}
) => {
    return (<Grid container direction="column" style={{ width:"100%",height: "100%", ...(backdropped? {backgroundColor:"#0000009f",color:"white", position:"absolute", top:0, bottom:0, right:0, left:0, zIndex:11000}:null)}} justify="center" alignContent="center" >
                    <Grid item style={{ textAlign: "center" }}>
                        <CircularProgress color="primary" /><br></br>
                        {label}
                    </Grid>
                </Grid>
    )
}

CenteredCircularProgress.propTypes = {
    label: PropTypes.string,
}
CenteredCircularProgress.defaultProps = {
    label: null,
}
export default CenteredCircularProgress
