import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

export const RoomSelector=(props) =>{
    const classes = useStyles();
    const { onClose, selectedValue, open, rooms } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} style={{zIndex:"2"}}>
            <DialogTitle id="simple-dialog-title">Pick a Floor to Visit</DialogTitle>
            <List>
                {rooms.map((room) => (
                    <ListItem button disabled = {rooms.id === selectedValue} onClick={() => handleListItemClick(room.id)} key={room.id}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <MeetingRoomIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={room.name} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

RoomSelector.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.number.isRequired,
    rooms:PropTypes.array.isRequired
};