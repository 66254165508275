import {ResponsivePicture} from "./constants";
import {Button, Grid, Link, Paper, Typography} from "@material-ui/core";
import {Close as CloseIcon, Instagram as InstagramIcon} from "@material-ui/icons";
import React from "react";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {isIOS} from 'react-device-detect'
const public_path = process.env.REACT_APP_PUBLIC;
const server_path = process.env.REACT_APP_SERVER;
export const Picture = (props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (

            <ResponsivePicture container style={{position: "fixed", height: "100%", overflowY: "auto", overflowX: "hidden", zIndex:"1001"}}
                  justify="center" alignItems={isIOS?"flex-start":"center"}>
                <Grid className="modal" item >
                    <Paper elevation={3}>
                        <Grid container>
                            {
                                matches?
                                    null
                                    :
                                    <Grid item xs={12} style={{textAlign:"right"}}>
                                        <Button className="close" onClick={props.onClose} color="primary"
                                                style={{fontSize: "1.5rem", fontWeight: "100"}}><CloseIcon/>
                                        </Button>
                                    </Grid>
                            }
                            <Grid item xs={12} sm={8} style={{textAlign: 'center', marginBottom:matches?"0px": "15px"}}>
                                <img  src={props.picture.image? server_path + props.picture.image:public_path + "/img/placeholder.png"}></img>
                            </Grid>
                            <Grid item  container xs={12} sm={4} direction="column" alignItems="center" justify="space-between" style={{width: "auto", textAlign:"center"}}>
                                {matches?
                                    <Grid item style={{alignSelf: "flex-end", marginBottom: "20px"}}>
                                        <Button className="close" onClick={props.onClose} color="primary"
                                                style={{fontSize: "1.5rem", fontWeight: "100"}}><CloseIcon/>
                                        </Button>
                                    </Grid>
                                    :null}
                                <Grid item style={{textAlign: "center"}}>
                                    <Typography variant="h5" style={{marginBottom: "5px", fontWeight: "bolder"}}>
                                        {props.picture.title || "Artwork name"}<br/>
                                    </Typography>
                                </Grid>
                                <Grid item style={{textAlign: "center", marginTop: "5px"}}>
                                    {
                                        props.picture.size.split("\n").map((e, i) => (
                                            <Typography style={{textAlign: "center"}} key={i}>
                                                {e}
                                            </Typography>
                                        ))
                                    }
                                </Grid>
                                <Grid item style={{textAlign: "center", marginTop: "5px"}}>
                                    {
                                        props.picture.info.split("\n").map((e, i) => (
                                            <Typography variant="body1" key={i}>
                                                {e}
                                            </Typography>
                                        ))
                                    }
                                </Grid>
                                <Grid item style={{textAlign: "center", marginTop: "5px"}}>
                                    {
                                        props.picture.link ?
                                            props.picture.linkTitle ?
                                                <Link target="_blank" href={props.picture.link}
                                                      color="primary">{props.picture.linkTitle}</Link>
                                                :
                                                <Link href={props.picture.link} target="_blank" color="primary">Link</Link>
                                            : null
                                    }
                                </Grid>
                                <Grid item></Grid>
                                <Grid item></Grid>
                                <Grid item></Grid>
                                <Grid item container justify="space-around" style={{marginTop: "20px"}}>
                                    <Grid item>
                                        <Button variant="contained" color="primary"
                                                href={`mailto:${process.env.REACT_APP_CONTACT}`}>
                                            Contact
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained"
                                                style={{padding: "0", minWidth: "36px", height: "36px"}}
                                                color="primary" aria-label="instagram" onClick={() => {
                                            window.open(process.env.REACT_APP_INSTAGRAM, "_blank")
                                        }}>
                                            <InstagramIcon style={{fontSize: "1.9rem"}}/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </ResponsivePicture>

    )
}
