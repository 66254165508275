import PropTypes from "prop-types";
import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { StyledTable } from './styled';
//import { localization } from 'tableLocale';
import isEqual from 'react-fast-compare';
export default class DataTable extends React.Component {
    componentDidUpdate(prevProps, prevState) {
        if (false) {
            Object.entries(this.props).forEach(([key, val]) => {
                if (!isEqual(prevProps[key], val)) {
                    console.log(`Prop '${key}' changed`);
                    console.log(`Prev:`);
                    console.log(prevProps[key]);
                    console.log(`New:`);
                    console.log(val);
                }
            });
            if (this.state) {
                Object.entries(this.state).forEach(([key, val]) => {
                    if (!isEqual(prevState[key], val)) {
                        console.log(`State '${key}' changed`);
                        console.log(`Prev:`);
                        console.log(prevState[key]);
                        console.log(`New:`);
                        console.log(val);
                    }
                });
            }
        }
    }
    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        return (
            <StyledTable
                title={this.props.title}
                data={this.props.data}
                columns={this.props.columns}
                hideToolbar={this.props.hideToolbar}
                hideTitle={this.props.hideTitle}
                hideTools={this.props.hideTools}
                options={
                    {
                        ...{
                            elevation: this.props.elevation,
                            selectableRows: 'single',
                            disableToolbarSelect: true,
                            selectableRowsOnClick: true,
                            selectableRowsHeader: false,
                            filter: false,
                            search: false,
                            print: false,
                            download: false,
                            viewColumns: false,
                            responsive: 'stacked',
                            serverSide: true,
                            count: this.props.count,
                            rowsPerPage: this.props.rowsPerPage,
                            page: this.props.page,
                            rowsPerPageOptions: [5, 10, 100],
                            onChangePage: (page) => {
                                this.props.onChangePage(page, this.props.rowsPerPage, this.props.order)
                            },
                            onChangeRowsPerPage: (rowsPerPage) => {
                                this.props.onChangeRowsPerPage(this.props.page, rowsPerPage, this.props.order)
                            },
                            onColumnSortChange: (column, direction) => {
                                const order = { column: column, direction: direction }
                                this.props.onColumnSortChange(this.props.page, this.props.rowsPerPage, order)
                            },
                            onTableChange: (action, tableState) => {
                                this.props.onTableChange(action, tableState)
                            },
                            //textLabels: localization
                        },
                        ...this.props.options
                    }
                }
                maxHeight={this.props.maxHeight}
            />
        );
    }
}

DataTable.defaultProps = {
    elevation: 3,
    hideToolbar: false,
    hideTitle: false,
    hideTools: false,
    count: 0,
    data: [],    
    options: null,
    order: null,    
    rowsPerPage: 5,
    title: "DataTable",
    
    onTableChange: () => { },
    onChangePage: () => { },
    onChangeRowsPerPage: () => { },
    onColumnSortChange: () => { },
    maxHeight: "none"
}

DataTable.propTypes = {
    
    hideToolbar: PropTypes.bool,
    hideTitle: PropTypes.bool,
    hideTools: PropTypes.bool,
    elevation: PropTypes.number,
    columns: PropTypes.array.isRequired,
    count: PropTypes.number,
    data: PropTypes.array,
    options: PropTypes.object,
    order: PropTypes.object,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onTableChange: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onColumnSortChange: PropTypes.func,
    maxHeight: PropTypes.string
}
