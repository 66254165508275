import {Button, Grid, Tooltip} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const RoundedButton = styled(Button)`
    background-color: white;
    color: ${props => props.theme.palette.primary.main};
    border-radius: 50px;
    padding: 
`
const HomeButton = ({text, tooltip, onClick, className}) => {
    return <Grid container justify={"center"} className={className}>
        <Grid item>
            <Tooltip title={tooltip}>
                <RoundedButton variant={"contained"} onClick={onClick}>{text}</RoundedButton>
            </Tooltip>
        </Grid>
    </Grid>;
}
export const StyledHomeButton = styled(HomeButton)`
    z-index:2;
    position:absolute;
    top:20px;    
    pointer-events:none;
    & button{        
        font-size: 16px;
        font-weight:bold;
        padding: 5px 35px;            
        pointer-events:auto;
    }
`