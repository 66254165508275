import {Admin} from 'pages/Admin';
import { Home } from 'pages/Home';

export const pages_routes = [
    {
        path: "/",
        exact: true,
        component: Home
    },
    {
        path: "/home",
        exact: true,
        component: Home
    },
    {
        path: "/admin",
        component: Admin,
        exact:false,
    }
]