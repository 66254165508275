import React from 'react';
import * as Utils from 'Utils'
import styled from 'styled-components';
const public_path = process.env.REACT_APP_PUBLIC;

const VisibilityCone = styled.img`
    transform: rotate(${props=>Math.PI +( -1*props.rotation)}rad);
    width:25px;
    position:absolute;
    top: ${props=> props.position.y}%;
    left: ${props=> props.position.x}%;
    transform-origin: 0 50%;
    top: calc( ${props=> props.position.y}% - 7.5px);
    left: calc(${props=> props.position.x}% + 5px);
`

const Indicator = styled.span`
    width:10px;
    height:10px;
    background-color: red;
    border-radius:50%;
    position:absolute;
    top: ${props=> props.position.y}%;
    left: ${props=> props.position.x}%;
`
export const PositionIndicator=(props)=>{
    const {position} = props;
    let rotation = props.rotation;
    if(Utils.isNU(position.x) ||Utils.isNU(position.y))
        return null
    else
        return (
            <>
                <VisibilityCone src={`${public_path}/img/visibilityCone.svg`}  position={position} rotation={rotation}/>
                <Indicator position={position}/>
            </>
            )
}