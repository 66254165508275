import {Button, Grid, Paper} from "@material-ui/core";
import {Close as CloseIcon} from "@material-ui/icons";
import React from "react";
const public_path = process.env.REACT_APP_PUBLIC;

export const HelpMessage = (props) => {
    return (
        <Grid container style={{position: "fixed", height: "100%", zIndex:"1001"}} justify="center" alignItems="center">
            <Grid item>
                <Paper style={{padding: "20px"}} elevation={3}>
                    <Grid container item direction="column" alignItems="center" justify="space-evenly"
                          style={{width: "auto"}}>
                        <Grid item style={{alignSelf: "flex-end"}}>
                            <Button onClick={props.onClose} color="primary"
                                    style={{fontSize: "1.5rem", fontWeight: "100"}}>Close this help<CloseIcon
                                style={{width: "3rem", height: "3rem"}}></CloseIcon> </Button>
                        </Grid>
                        <Grid item>
                            <img src={public_path + "/img/help2.png"}></img>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}