import React, { useEffect, useState } from 'react'
import LinearProgressWithLabel from 'components/LinearProgressWithLabel'
import { CSSTransitionGroup } from 'react-transition-group' // ES6
import { RoomsProvider } from 'serviceProviders/roomProvider'
import { Map } from 'components/Map'
import Panolens from 'components/Panolens'
import { Picture } from "./Picture";
import { HelpMessage } from "./HelpMessage";
import { RoomSelector } from "./RoomSelector";
import { PermissionMessage } from "./PermissionMessage";
import { transitionOptions } from "./constants";
import { isIOS, isMobile } from 'react-device-detect';
import * as Utils from 'Utils';
import { StyledHomeButton } from "./HomeButton";
import { StyledRoomSelectorButton } from "./RoomSelectorButton";
import { Credits } from "./Credits";


export const Home = () => {
    const [loading, setLoading] = useState(false);
    const [showHelp, setShowHelp] = useState(true);
    const [progress, setProgress] = useState(0);
    const [showPicture, setShowPicture] = useState(false);
    const [slot, setSlot] = useState(null);
    const [rooms, setRooms] = useState([]); //available rooms
    const [room, setRoom] = useState(null); //current room
    const [roomSelectorToggle, setRoomSelectorToggle] = useState(false);
    const [permission, setPermission] = useState('pending');
    const [point, setPoint] = useState(null)
    const [cameraRotation, setCameraRotation] = useState(0)
    const RequestIOSPermission = () => {
        DeviceOrientationEvent.requestPermission()
            .then(response => {
                setPermission(response)
                setLoading(true)
                RoomsProvider.all({}).then(response => {
                    setRooms(response.rooms);
                    if (response.rooms.length > 0 && response.rooms.find(r => r.id === 1)) {
                        handleRoomSelected(1);
                    }
                });
            })
            .catch((error) => {
                setPermission('error')
            })
    }

    useEffect(() => {
        if (Utils.isIOS13()) {
            console.log("IOS")
            RequestIOSPermission()
        } else {
            setLoading(true)
            RoomsProvider.all({}).then(response => {
                setRooms(response.rooms);
                if (response.rooms.length > 0 && response.rooms.find(r => r.id === 1)) {
                    handleRoomSelected(1);
                }
            });
        }
    }, [])
    setTimeout(() => {
        setShowHelp(false);
    }, 150000);

    const handlePictureClicked = (slot) => {
        setSlot(slot)
        setShowPicture(true)
    }
    const handlePictureClosed = () => {
        setSlot(null);
        setShowPicture(false)
    }
    const handleRoomSelectorToggle = () => {
        setRoomSelectorToggle(!roomSelectorToggle)
    }
    const handleRoomSelected = (id) => {
        if (id !== null && id !== 0)
            RoomsProvider.get(id, null).then(response => {
                setRoom(response);
            });
        setRoomSelectorToggle(false);
    }
    const handleHomeButtonClicked = () => {
        window.open(process.env["REACT_APP_HOMEPAGE"]);
    }
    const isIOS13 = Utils.isIOS13()
    return (
        <div style={{ width: "auto" }}>
            {
                isIOS13 && permission !== 'pending' && permission !== 'error' || !isIOS13 ?
                    <>
                        <CSSTransitionGroup {...transitionOptions}>
                            {
                                showHelp
                                    ? <HelpMessage key="help"
                                        onClose={() => setShowHelp(false)} />
                                    : null
                            }
                        </CSSTransitionGroup>

                        <CSSTransitionGroup {...transitionOptions}>
                            {
                                showPicture
                                    ? <Picture key="picture"
                                        picture={slot.picture}
                                        onClose={handlePictureClosed} />
                                    : null
                            }
                        </CSSTransitionGroup>
                        {
                            loading ?
                                <div style={{ position: "fixed", width: "100vw", textAlign: "center", zIndex: "10010" }}>
                                    <LinearProgressWithLabel value={progress} />
                                </div>
                                :
                                null
                        }
                        {
                            room ?
                                <>
                                    <CSSTransitionGroup {...transitionOptions}>
                                        <StyledHomeButton text={"Tyler Shields"} tooltip={"Go to Tyler Shields Website"}
                                            onClick={handleHomeButtonClicked} />
                                    </CSSTransitionGroup>

                                    <CSSTransitionGroup {...transitionOptions}>
                                        <Credits />
                                    </CSSTransitionGroup>

                                    {
                                        rooms.length > 1 ?
                                            <>
                                                <CSSTransitionGroup {...transitionOptions}>
                                                    <StyledRoomSelectorButton onClick={handleRoomSelectorToggle} />
                                                </CSSTransitionGroup>
                                                <RoomSelector
                                                    rooms={rooms}
                                                    onClose={handleRoomSelected}
                                                    open={roomSelectorToggle}
                                                    selectedValue={(room && room.id) || 0}
                                                />
                                            </>
                                            : null
                                    }

                                    <CSSTransitionGroup {...transitionOptions}>
                                        {
                                            point ?
                                                <Map
                                                    image={room.template.minimap}
                                                    indicator={{
                                                        position: { x: point.map_pos_x, y: point.map_pos_y },
                                                        rotation: cameraRotation
                                                    }}
                                                    xs={"125px"}
                                                    md={"150px"}
                                                    lg={"200px"}
                                                />
                                                : null
                                        }
                                    </CSSTransitionGroup>
                                    <Panolens
                                        onLoading={(progress) => {
                                            setProgress(progress);
                                        }}
                                        onLoaded={() => {
                                            setProgress(0);
                                            setLoading(false)
                                        }}
                                        onPictureClicked={handlePictureClicked}
                                        onRoomSelectorClicked={handleRoomSelectorToggle}
                                        onRotationChanged={(rotation) => {
                                            setCameraRotation((rotation._y) - Math.PI - Math.PI / 2);
                                        }}
                                        onPointChanged={setPoint}
                                        room={room}
                                        roomsCount={rooms.length}
                                        sensor={(Utils.isIOS13() && permission === "granted") || (isMobile && !isIOS)}
                                        style={{ width: "auto", height: "100vh" }}>
                                    </Panolens>
                                </>
                                : null
                        }

                    </>
                    :
                    permission === "error" ?
                        <PermissionMessage onRequest={RequestIOSPermission} />
                        :
                        null
            }
        </div>
    )
}

