import React from 'react';
import {PositionIndicator} from "../PositionIndicator";
import styled from "styled-components"

const ResponsiveDiv = styled.div`
    z-index:2;
    position:absolute;
    margin:20px 20px 20px 5px;
    float: left;
    max-width:150px;
    bottom:5px;  
    ${props => props.theme.breakpoints.up("xs")} {
        ${props=>props.xs? "max-width:" + props.xs:null}
    }
    ${props => props.theme.breakpoints.up("sm")} {
        margin:20px 20px 20px 20px;
        bottom:unset;  
        ${props=>props.sm? "max-width:" + props.sm:null}
    }
    ${props => props.theme.breakpoints.up("md")} {
        ${props=>props.md? "max-width:" + props.md:null}
    }
    ${props => props.theme.breakpoints.up("lg")} {
        ${props=>props.lg? "max-width:" + props.lg:null}
    }    
    ${props => props.theme.breakpoints.up("xl")} {
        ${props=>props.xl? "max-width:" + props.xl:null}
    }
`

const public_path = process.env.REACT_APP_PUBLIC;
const server_path = process.env.REACT_APP_SERVER;
const isLocal = process.env.REACT_APP_ENV==='local';
export const Map=(props)=>{
    const url = isLocal? public_path + props.image: server_path + props.image;
    const {xs, sm, md, lg, xl} = props;

    return (
            <ResponsiveDiv xs = {xs} sm = {sm}  md = {md} lg = {lg} xl = {xl}>
                {props.image?
                    <img src={url} style={{display:"block"}}/>
                    :null
                }
                <PositionIndicator
                    position={props.indicator.position}
                    rotation={props.indicator.rotation}
                />
            </ResponsiveDiv>

    )
}