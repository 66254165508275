import printError from 'error'
import axios from 'axios'
import { getConfigs } from 'axiosConfig'
const apiPath = process.env.REACT_APP_API;

export const AuthProvider = {
    login : (password, cancelToken=null) => {
        return axios.post(`${apiPath}login`, {password:password},{
            ...getConfigs(), cancelToken:cancelToken,
        }).then(response=>{
            sessionStorage.setItem('loggedIn',response.data.validation);
            return Promise.resolve(response.data.validation)
        }).catch(err=>{
            printError(err);
            sessionStorage.setItem('loggedIn',false);
            return Promise.reject(err);
        })
    },
}
