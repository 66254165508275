import React, { Component } from 'react'
import { Grid, TextField, Paper, Typography, Button, Tooltip } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import FullScreenDialog from 'components/FullScreenDialog'
import SimpleDialog from 'components/SimpleDialog'
import CenteredCircularProgress from 'components/CenteredCircularProgress'
import {RoomsProvider} from 'serviceProviders/roomProvider'
import {TemplatesProvider} from 'serviceProviders/templateProvider'
import {PicturesProvider} from 'serviceProviders/pictureProvider'
import UploadFile from '../../../components/UploadFile/UploadFile';
import * as Utils from 'Utils';
import Combo from 'components/Combo'
import _ from 'lodash'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import ImageSelector from '../../../components/ImageSelector/ImageSelector';
import axios from 'axios'

const public_path = process.env.REACT_APP_PUBLIC;
const server_path = process.env.REACT_APP_SERVER;

const emptyRoom = {
    name: null,
    template: null,
    pictures: [],
    points: [],
}

const initialState = {
    templates: [],
    pictures: [],
    submitting: false,
    templatesLoading: false,
    picturesLoading: false,
    accordion: false,
    room: emptyRoom, loadingRoom: false,
    errors: null,
    showErrors:false,
}

class RoomsForm extends Component {
    state = { ...initialState }

    componentDidMount() {
        this.source = axios.CancelToken.source()
        this.setState({ templatesLoading: true, picturesLoading: true })
        TemplatesProvider.all(this.source.token).then((data) => {
            this.setState({ templates: data })
        }).catch((err) => {
            if (!axios.isCancel(err))
                this.setState({ templatesLoading: false })

        })

        PicturesProvider.all({ filters: { title: null, available: true, date: null, size: null } }, this.source.token).then((data) => {
            this.setState({ pictures: data.pictures, picturesLoading: false })
        }).catch((err) => {
            if (!axios.isCancel(err))
                this.setState({ picturesLoading: false })
        })
    }

    componentWillUnmount() {
        this.source.cancel("")
    }

    componentDidUpdate(oldProps, oldState) {
        if (!oldProps.open && this.props.open) {
            this.setState({ loadingRoom: this.props.room ? true : false })
            PicturesProvider.all({ filters: { title: null, available: true, date: null, size: null } }, this.source.token).then((response_1) => {
                if (this.props.room) {
                    RoomsProvider.get(this.props.room.id).then(response_2 => {
                        let pictures = []
                        let points = []
                        let _pictures = [...response_1.pictures]
                        response_2.points.forEach(point => {
                            point.slots.forEach(slot => {
                                if (slot.picture) {
                                    if (!pictures.find(p => p.id === slot.picture.id)) {
                                        pictures = [slot.picture,...pictures]
                                    }
                                    if (!_pictures.find(p => p.id === slot.picture.id)) {
                                        _pictures = [slot.picture,..._pictures]
                                    }
                                }
                            })
                            points = [...points, { image: point.pivot.image, point_id: point.id }]
                        });

                        let room = { ...response_2, pictures: pictures, points: points, template: this.state.templates.find(t => t.id === response_2.template_id) }
                        room = _.pick(room, ["id", "name", "template", "pictures", "points"])
                        this.setState({ room: room, pictures: _pictures })
                    }).finally(() => {
                        this.setState({ loadingRoom: false })
                    })
                }
                else {
                    this.setState({ pictures: response_1.pictures, picturesLoading: false })
                }
            }).catch((err) => {
                if (!axios.isCancel(err))
                    this.setState({ picturesLoading: false })
            })
        }
    }

    options = () => {
        return {
            showClose: true,
            actions: [
                {
                    label: "SAVE",
                    disabled: this.state.submitting,
                    onClick: () => {
                        this.setState({ submitting: true })

                        if (this.validate()) {
                            if (!this.state.room.id) {
                                RoomsProvider.post(this.state.room, this.source.token)
                                    .then(res => {
                                        this.handleClose()
                                    }).catch(error => {
                                        if (error && error.data) {
                                            this.setState({ errors: error.data })
                                        }
                                    }).finally(() => {
                                        this.setState({ submitting: false })
                                    })
                            }
                            else {
                                RoomsProvider.put(this.state.room, this.source.token)
                                    .then(res => {
                                        this.handleClose()
                                    }).catch(error => {
                                        if (error && error.data) {
                                            this.setState({ errors: error.data })
                                        }
                                    }).finally(() => {
                                        this.setState({ submitting: false })
                                    })
                            }
                        }
                        else {
                            this.setState({ submitting: false })
                        }
                    }
                }
            ]
        }
    }

    validate = () => {
        const room = this.state.room
        let errors = null

        if (Utils.isNEU(room.name)) {
            errors = { ...errors, name: "The name field is required." }
        }

        if (Utils.isNEU(room.template)) {
            errors = { ...errors, template: "The template field is required." }
        }
        else {

            if (room.template.points.filter(point => point.slots.length > 0 && point.slots.find(slot => !room.pictures.map(p => p.slot_id).includes(slot.id))).length > 0) {
                //there is a slot that is not covered by any picture - missing assignation
                errors = { ...errors, slots: "All slots must have a picture assigned." }
            }
    
            if (room.template.points.filter(point => !room.points.map(p => p.point_id).includes(point.id)).length > 0) {
                //there is a point without an uploaded image - missing panorama
                errors = { ...errors, points: "All panoramas must have an image uploaded." }
            }
        }

        this.setState({ errors: errors, showErrors: errors !== null })
        return errors === null
    }

    handleInputChange = (e) => {
        this.setState({
            room: { ...this.state.room, [e.target.name]: e.target.name === "image" ? e.target.files[0] : e.target.value }
        })
    }

    handleTemplateSelected = (e, option) => {
        if (Utils.isNEU(option))
            this.setState({ room: { ...this.state.room, points: [], pictures: [], template: null }, accordion: null });
        else
            this.setState({ room: { ...this.state.room, template: option }, accordion: option.points[0].id + "-" + option.points[0].slots[0].id });
    }

    handlePictureSelected = (picture, slot) => {
        let roomPictures = _.cloneDeep(this.state.room.pictures)
        picture.room_id = this.state.room.id ? this.state.room.id : null
        const aux = roomPictures.find(p => p.slot_id === slot.id)
        if (aux) {
            roomPictures = roomPictures.filter(p => p.id !== aux.id)
            if (aux.id !== picture.id)
                roomPictures = [...roomPictures, { ...picture, slot_id: slot.id }]
        }
        else
            roomPictures = [...roomPictures, { ...picture, slot_id: slot.id }]


        this.setState({ room: { ...this.state.room, pictures: roomPictures } })
    }

    handleClose = () => {
        this.setState({ room: emptyRoom, errors: null })
        this.props.onClose()
    }

    handleAccordionChange = (slotId) => (event, isExpanded) => {
        this.setState({ accordion: isExpanded ? slotId : false });
    };

    handlePointImageChange = (e, point) => {
        let roomPoints = _.cloneDeep(this.state.room.points)
        const aux = roomPoints.find(p => p.point_id === point.id)
        if (aux) {
            aux.image = e.target.files[0]
        }
        else {
            roomPoints = [...roomPoints, { point_id: point.id,imageType:point.imageType, image: e.target.files[0] }]
        }

        this.setState({ room: { ...this.state.room, points: roomPoints } })
    }

    handleDownloadRequest = (point_id)=>{
        TemplatesProvider.download(point_id, this.source.token)
    }

    render() {
        const { open, title, theme } = this.props
        const { room, showErrors, errors, templates, templatesLoading, accordion, pictures, loadingRoom,submitting } = this.state
        return (
            <>

                <SimpleDialog
                    open={showErrors && !Utils.isNEU(errors) && (!Utils.isNEU(errors.slots) || !Utils.isNEU(errors.points))}
                    title="Error!"
                    text={(errors && errors.slots) || ((errors && errors.points) || "")}
                    showCancel={false}
                    showAccept={true}
                    acceptLabel="OK"
                    onAccept={()=>this.setState({showErrors:false})}
                />

                <FullScreenDialog
                    open={open}
                    title={title}
                    onClose={this.handleClose}
                    options={this.options()}
                >
                    {
                        submitting?
                            <CenteredCircularProgress backdropped={true} label="Saving Floor, please wait..." />
                            :null
                    }
                    {loadingRoom ?
                        <CenteredCircularProgress label="Loading Floor..." />
                        :
                        <Grid container spacing={2}>
                            <Grid container direction="column" item xs={12} md={8} style={{ margin: "auto" }} spacing={3}>
                                <Grid item>
                                    <TextField
                                        error={!Utils.isNEU(errors) && !Utils.isNEU(errors.name)}
                                        helperText={(errors && errors.name || "")}
                                        fullWidth={true}
                                        label="Name"
                                        name="name"
                                        value={room.name || ""}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Combo
                                        label="Room Template"
                                        error={errors && errors.template}
                                        value={room.template}
                                        options={templates}
                                        onChange={this.handleTemplateSelected}
                                        config={{
                                            loading: templatesLoading,
                                            getOptionLabel: (option) => option.name,
                                            disabled: templates.length <= 0
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="column" item xs={12} md={7} spacing={3} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <Grid item style={{ position: "sticky", top: "0" }}>
                                    <Typography style={{ marginBottom: "5px" }}>Current template panorama preview:</Typography>
                                    <img style={{ boder: "5px" }} src={room.template && accordion ? server_path + room.template.points.find(p => p.id == accordion.split("-")[0]).image : public_path + "/img/placeholder.png"}></img>
                                </Grid>
                            </Grid>
                            <Grid container direction="column" item xs={12} md={5} spacing={3} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                {
                                    room.template ?
                                        <>
                                            {
                                                room.template.points.map((point, i) => {
                                                    return (
                                                        <Grid key={point.id} item>
                                                            <Paper elevation={3} style={{
                                                                padding: theme.spacing(2),
                                                            }}>
                                                                <Grid container justify={"space-between"}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Typography style={{ marginBottom: "10px", paddingTop:"10px" }}>Panorama {i + 1}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Tooltip title={"Download Panorama template"}>
                                                                            <Button fullWidth variant ="outlined" color={"primary"} onClick={()=>this.handleDownloadRequest(point.id)}>
                                                                                <GetAppIcon style={{ marginRight: "10px" }} />Download Template {i + 1}
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>

                                                                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                                                                    <Tooltip title={"Upload Modified Panorama"}>
                                                                        <UploadFile
                                                                            name={"image" + point.id}
                                                                            accept=".jpg,.png"
                                                                            multiple={false}
                                                                            count = {room.points.filter(p=>p.point_id === point.id).length}
                                                                            onFilesSelected={(e) => this.handlePointImageChange(e, point)}
                                                                        >
                                                                            <CloudUploadIcon style={{ marginRight: "10px" }} /> Upload Panorama {i + 1}
                                                                        </UploadFile>
                                                                    </Tooltip>
                                                                </div>
                                                                <Typography style={{ marginBottom: "10px" }}>Slots
                                                            </Typography>

                                                                {
                                                                    point.slots.map(slot => {
                                                                        const slotId = point.id + "-" + slot.id
                                                                        return (
                                                                            <Accordion key={slotId} expanded={accordion === slotId} onChange={this.handleAccordionChange(slotId)}>
                                                                                <AccordionSummary
                                                                                    expandIcon={<ExpandMoreIcon />}
                                                                                    aria-controls="panel1bh-content"
                                                                                    id="panel1bh-header"
                                                                                >
                                                                                    Slot: {slot.name}
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <Grid container direction="column">
                                                                                        <Grid item>
                                                                                            <Typography style={{ fontSize: ".9rem", marginBottom: "5px" }}>Select one picture to fill this slot:
                                                                                        </Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <ImageSelector
                                                                                                data={
                                                                                                    pictures.filter(p => !room.pictures.map(_p => _p.id).includes(p.id) || room.pictures.find(_p => _p.id === p.id && _p.slot_id === slot.id))}
                                                                                                selected={room.pictures.find(p => p.slot_id === slot.id)}
                                                                                                onImageSelected={(picture) => this.handlePictureSelected(picture, slot)}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        )
                                                                    })
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    }
                </FullScreenDialog>
            </>
        )
    }
}

export default withStyles(null, { withTheme: true })(RoomsForm)
