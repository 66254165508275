import React from 'react';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom'
import CustomAppBar from 'components/CustomAppBar/CustomAppBar'
import PanoramaHorizontalIcon from '@material-ui/icons/PanoramaHorizontal';
import { sidebarItems } from './sidebarItems';
import {AuthProvider} from "../../serviceProviders/authProvider";
import {Grid, Paper, TextField, Button, Typography} from "@material-ui/core";


export const Admin = withRouter((props) => {
    const [menuItems, setMenuItems] = React.useState(sidebarItems)
    const [loggedIn, setLoggedIn] = React.useState(false)
    const [password, setPassword] = React.useState(null)
    const [error, setError] = React.useState(null)
    const handleMenuItemClicked = (index, id) => {
        const item = menuItems[index].find(item => item.id === id)
        setMenuItems(menuItems.map(
            group => group.map(
                item => ({
                    ...item,
                    isActive: item.id === id
                })
            )
        ))
        props.history.push(item.path)
    }
    React.useEffect(() => {
        if(sessionStorage.getItem('loggedIn')==='true'){
            setLoggedIn(true)
        }
    }, []);
    const handleLogin=()=>{
        AuthProvider.login(password).then((validation)=>{
            if(!validation)
                setError('The password is incorrect.')

            setLoggedIn(validation)
        }).catch(err=>{
            const error = err&&err.response&&err.response.data&&err.response.data.password&& err.response.data.password[0]||"An error has occured, try again later..."
            setError(error)
        })
    }
    return (
        <>
            {
                loggedIn?
                    <CustomAppBar
                        title="Museo 360"
                        icon={<PanoramaHorizontalIcon />}
                        menuItems={menuItems}
                        onMenuItemClicked={handleMenuItemClicked}
                    >
                        <Switch>
                            {
                                menuItems.map(items => items.map(item => <Route path={item.path} exact={item.exact}>
                                    <item.component {...item.props} />
                                </Route>))
                            }
                        </Switch>
                    </CustomAppBar>
                    :
                    <Grid container style={{height:'calc( 100vh)'}} justify={"center"} alignItems={"center"}>
                        <Grid item>
                            <Paper elevation={3} style={{padding:"15px", textAlign:"center", width:'350px', height:'350px'}}>
                                <Grid container direction={"column"} style={{height:'100%'}} justify={'space-between'}>
                                    <Grid item >
                                        <Typography color="primary" variant={"h4"}>
                                            LOGIN
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography color="primary">
                                            Please enter the password to access the Administration Panel.
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{marginTop:"10px"}}>
                                        <TextField
                                            error={error||false}
                                            helperText={error||''}
                                            fullWidth={true}
                                            type={"password"}
                                            label={"Password"}
                                            value={password}
                                            onChange={(e)=>{setPassword(e.target.value)}}
                                        />
                                    </Grid>
                                    <Grid item style={{marginTop:"10px"}}>
                                        <Button color={"primary"} variant={"outlined"} style={{ width:"100%"}} onClick={handleLogin}>
                                            Login
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
            }
        </>

    )
})
