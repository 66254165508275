import styled from "styled-components";
import {Grid} from "@material-ui/core"
export const ResponsivePicture = styled(Grid)`
    z-index:1001;
    &  h5{
        font-size: 1rem;
    }
    & .close .MuiSvgIcon-root{
        width:2.5rem;
        height:2.5rem;
    }
    & .modal{
        width: 95vw;
    }
    & .modal .MuiPaper-root{
        padding:10px;
        padding-bottom:15px;
    }    
    & img { 
               
        max-height:40vh;
        ${props=>props.theme.breakpoints.up('sm')}{            
            max-height:93vh;
        }
    }

    ${props => props.theme.breakpoints.up('md')}{
        &  h5{font-size:1.5rem;}
        &  .close .MuiSvgIcon-root{
            width:3rem;
            height:3rem;
        }
        & .modal{
            width: 70vw;
        }
        & .modal .MuiPaper-root{
            padding:15px
        }
        & img {            
            height:80vh;
            object-fit:contain;            
        }
    }
`
export const transitionOptions = {
    transitionName: "fade",
    transitionAppear: true,
    transitionAppearTimeout: 1000,
    transitionEnterTimeout: 1000,
    transitionLeaveTimeout: 1000
}