import React from 'react'
import styled from 'styled-components'

const Button = styled.label`
    padding: 12px;    
    font-size:1.2rem;    
    cursor:pointer;
    background-color:${props => props.theme.palette.primary.main};
    width: 100%;
    display: block;
    border-radius: 5px;
    color: white;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    & > *{
        vertical-align:sub
    }
`
export default function UploadFile(props) {
    return (
        <Button htmlFor={props.name} >
            {props.children}
            <span style={{marginLeft:"auto", float:"right"}}>
                {props.count?`(${props.count})`: null}
            </span>
            <input
                style={{ position: 'absolute', width: '0px', height: '0', opacity: '0' }}
                type="file"
                accept={props.accept || "*"}
                id={props.name}
                name={props.name}
                multiple={props.multiple || false}
                onChange={props.onFilesSelected}
            />

        </Button>
    )
}
