import printError from 'error'
import axios from 'axios'
import { getConfigs } from 'axiosConfig'
import * as Utils from 'Utils'
const apiPath = process.env.REACT_APP_API;

export const TemplatesProvider = {
    all:(cancelToken) => {
        return axios.get(apiPath + "templates", {
            ...getConfigs(),
            cancelToken: cancelToken
        }).then(res => {
            return Promise.resolve(res.data.templates)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },
    download:(point_id)=>{
        window.open(apiPath + "templates/download/" + point_id);
    }
}

