import React, { PureComponent } from 'react'
import DataTable from 'components/DataTable'
import _ from 'lodash'
import * as Utils from 'Utils'
import { Grid, Typography, IconButton, Paper, Divider, LinearProgress } from '@material-ui/core'
import { Delete as DeleteIcon, AddCircle as AddCircleIcon, Edit as EditIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import PicturesForm from 'pages/PicturesPage/PicturesForm'
import SimpleDialog from 'components/SimpleDialog'
import {PicturesProvider} from 'serviceProviders/pictureProvider'
import axios from 'axios'
import moment from 'moment'
const server_path = process.env.REACT_APP_SERVER;
class PicturesPage extends PureComponent {
    state = {
        openForm: false,
        pictures: [],
        loading: false,
        selectedPicture: null,
        showConfirmation: false,
        datatable: {
            page: 0,
            rowsPerPage: 5,
            total: 0,
            order: null
        }
    }
    componentDidMount() {
        this.source = axios.CancelToken.source()
        this.refreshTable()
    }
    componentWillUnmount() {
        this.source.cancel("")
    }
    columns = () => [
        {
            label: 'Id', name: 'id', options: {
                display: "excluded"
            }
        },
        { label: 'Picture', name: 'image',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <img src={server_path + value} style={{
                        width: "60px",
                        height: "60px",
                        borderRadius:"60px",
                        objectFit: "cover"}}/>
                )
            }
        },
        { label: 'Title', name: 'title' },
        { label: 'Created at', name: 'created_at',
            options:{
                customBodyRender:(value)=>moment(value).format('L HH:mm')
            }
        },
        { label: 'Sizes', name: 'size' }
    ];
    options = () => {
        return   {
            rowsPerPageOptions: [5, 10, 20],
            onRowSelectionChange: this.onRowsSelect,
            rowsSelected: this.state.selectedPicture ? [this.state.pictures.findIndex(p => this.state.selectedPicture.id === p.id)] : []
        }
    }
    
    onRowsSelect = (c, a, r) => {
        if (!this.state.loadingStudies) {
            if (r.length > 0) {
                const picture = _.cloneDeep(this.state.pictures[r[0]])
                if (picture) {
                    this.setState({ selectedPicture: picture })
                    return
                }
            }
        }
        this.setState({ selectedPicture: null })
    }
    refreshTable = (page = this.state.datatable.page, rowsPerPage = this.state.datatable.rowsPerPage, order = this.state.datatable.order) => {
        this.setState({ loading: true })
        PicturesProvider.all({ page: page, rowsPerPage: rowsPerPage, order: order }, this.source.token).then(data => {
            const aux = Math.ceil(data.total / rowsPerPage)
            page = page <= aux - 1 ? page : aux === 0 ? 0 : aux - 1
            if (this.state.datatable.page !== page) {
                this.refreshTable(page, rowsPerPage, order)
            }
            else { 
                this.setState({ pictures: data.pictures, datatable: { ...this.state.datatable, total: data.total, rowsPerPage: rowsPerPage, order: order, page: page  }, loading: false });
                if (data.pictures.length === 0)
                    this.setState({ selectedPicture: null })
            }

        }).catch((err) => {
            if (!axios.isCancel(err)) {
                this.setState({ loading: false })
            }
        })
    }
    handleFormOpened = (action) => {
        this.setState({ openForm: true, selectedPicture: action === "create" ? null : this.state.selectedPicture })
    }
    handleFormClosed = () => {
        this.setState({ openForm: false, selectedPicture:null}, ()=>{
            this.refreshTable()
        })
    }
    handleShowConfirmation = () => {
        this.setState({ showConfirmation: true })
    }
    handleDelete = () => {
        if (this.state.selectedPicture) {
            PicturesProvider.del(this.state.selectedPicture.id).then(() => {
                this.refreshTable()
            })
        }
    }

    render() {
        const { theme } = this.props
        const { openForm, pictures, loading, datatable, selectedPicture } = this.state
        return (
            <>
                <SimpleDialog
                    open={this.state.showConfirmation}
                    title="Delete Picture"
                    text="Are you sure you want to delete this picture? This action can't be undone."
                    acceptLabel="Yes, I'm sure!"
                    cancelLabel="No"
                    showCancel={true}
                    onAccept={() => { this.handleDelete(); this.setState({ showConfirmation: false }) }}
                    onClose={() => { this.setState({ showConfirmation: false }) }}
                />
                <PicturesForm
                    title={selectedPicture ? "Edit Picture" : "New Picture"}
                    open={openForm}
                    onClose={this.handleFormClosed}
                    picture={selectedPicture}
                />
                <Paper elevation={3} style={{
                    padding: theme.spacing(2),
                    height: "100%"
                }}>
                    <Grid container alignItems="flex-end" spacing={1}>
                        <div style={{
                            width: "100%"
                        }}>
                            <Grid item xs={12} container style={{
                                width: "100%"
                            }} alignItems="center">
                                <Grid item style={{
                                    flexGrow: 1
                                }}>
                                    <Typography variante="h6">
                                        Pictures
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton edge="start" color="primary" onClick={() => this.handleFormOpened("create")} aria-label="Add New Picture">
                                        <AddCircleIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={Utils.isNEU(selectedPicture)} edge="start" color="primary" onClick={() => this.handleFormOpened("edit")} aria-label="Edit Picture">
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={Utils.isNEU(selectedPicture)} edge="start" color="primary" onClick={this.handleShowConfirmation} aria-label="Delete Picture">
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            {
                                loading
                                    ? <LinearProgress />
                                    : null
                            }
                            <DataTable
                                title="Pictures"
                                elevation={0}
                                columns={this.columns()}
                                data={pictures}
                                count={datatable.total}
                                rowsPerPage={datatable.rowsPerPage}
                                page={datatable.page}
                                onChangePage={(page, rowsPerPage, order) => { this.setState({ datatable: { ...datatable, page: page } }); this.refreshTable(page, rowsPerPage) }}
                                onChangeRowsPerPage={this.refreshTable}
                                onColumnSortChange={this.refreshTable}
                                hideToolbar={true}
                                options={this.options()}
                            />
                        </div>
                    </Grid>
                </Paper>
            </>
        )
    }
}

export default withStyles(null, { withTheme: true })(PicturesPage)