import React, {Component} from 'react';
import {ImagePanorama, CubePanorama, Viewer, Infospot, ImageLoader} from 'panolens';
import styled from 'styled-components';
import * as THREE from 'three';
import * as Utils from 'Utils';
import _ from 'lodash';

const server_path = process.env.REACT_APP_SERVER;
const public_path = process.env.REACT_APP_PUBLIC;
const env = process.env.REACT_APP_ENV;
const TWEEN = window.TWEEN


export function fadeMesh(mesh, from, to, options) {
    options = options || {};    
    const direction = to > from ? 'in' : 'out'    
    var mat = mesh.material,
        original = mesh.material.opacity,
        easing = options.easing || TWEEN.Easing.Linear.None,
        duration = options.duration || 150;
    // check to make sure originals exist
    var current = {percentage: (original - from) / (to - from)}
    // tween opacity back to originals
    var tweenOpacity = new TWEEN.Tween(current)
        .to({percentage: 1}, duration)
        .easing(easing)
        .onUpdate(function () {
            mat.opacity = (to - from) * current.percentage + from;
        })
        .onComplete(function () {
            if (options.callback) {
                options.callback();
            }
        });
    tweenOpacity.start();
    return tweenOpacity;
}

const StyledDiv = styled.div`
    position:relative;
    width:${props => props.width}!important;    
    height:${props => props.height}!important;
    background-color:white!important;
    max-height: 100vh;
    max-width: 100vw;
    overflow:hidden;    
`

export default class Panolens extends Component {
    state = {
        points: [],//{...point, roomId}
        rooms: [],//{roomId, startPanorama}
        panoramas: [], //[{roomId:<room_id>, pointId:<point_id>, panorama: <obj>}]
        viewer: null,
        viewerConfigured:false,
        cameraRotation:{_x:null, _y:null, _z:null}
    }

    componentDidMount() {
        console.log("SettingUp")
        this.setUpFloor()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.room.id !== prevProps.room.id) {
            this.clean().then(() => {
                this.setUpFloor()
            })
        }
    }

    handleInfoSpotClick = (picture) => {
        this.props.onPictureClicked(picture)
    }

    cleanPictures = (pointId, roomId) => {
        this.disablePictures(pointId, roomId)
        this.enablePictures(pointId, roomId)
    }
    
    disablePictures = (pointId, roomId) => {
        const {points} = this.state
        points.filter(p => !(p.id === pointId && p.roomId === roomId)).forEach(point => {
            point.slots.forEach(slot => {
                slot.tile.visible = false
            });
        });
    }
    
    enablePictures = (pointId, roomId) => {
        const {points} = this.state
        points.find(p => p.id === pointId && p.roomId === roomId).slots.filter(s => !Utils.isNEU(s.tile)).forEach(slot => {
            slot.tile.visible = true
        })
    }

    createPanorama = (viewer, room, point, panoramas) => {
        let panorama = panoramas.find(_p => _p.pointId === point.id && _p.roomId === room.id);
        if (!panorama) {
            var loader = new THREE.ImageLoader();
            loader.crossOrigin = '';
            var url = (env === "local" ? public_path : server_path) + point.pivot.image
            if (point.imageType === 'cube') {
                let baseUrl = url.split("/")
                const name = baseUrl[baseUrl.length - 1]
                baseUrl = baseUrl.filter((x, i) => i !== baseUrl.length - 1).join("/")
                const posX = baseUrl + "/posX_" + name ;
                const negX = baseUrl + "/negX_" + name ;
                const posY = baseUrl + "/posY_" + name ;
                const negY = baseUrl + "/negY_" + name ;
                const posZ = baseUrl + "/posZ_" + name ;
                const negZ = baseUrl + "/negZ_" + name ;
                panorama = new CubePanorama([
                    posX, negX,
                    posY, negY,
                    posZ, negZ]
                );
            } else {
                loader.load(url)
                panorama = new ImagePanorama(url);
            }
            panorama.addEventListener('enter-fade-start', () => {
                this.disablePictures(point.id, room.id)
            });
            panorama.addEventListener('enter-fade-complete', () => {
                this.enablePictures(point.id, room.id)
            });
            panorama.addEventListener('progress', (event) => {
                let progress = event.progress.loaded / event.progress.total * 100;
                this.props.onLoading(progress, point, panoramas)
                if (progress === 100) {
                    this.props.onLoaded(point, panoramas)
                }
            });

            panoramas.push({roomId: room.id, pointId: point.id, panorama: panorama})

        } else {
            panorama = panorama.panorama;
        }
        return panorama
    }

    createPicture = (slot, panorama) => {
        const {pos_x, pos_y, pos_z, rot_x, rot_y, rot_z, scale_x, scale_y, scale_z} = slot.pivot
        var geometry = new THREE.PlaneGeometry(15, 15);
        var loader = new THREE.ImageLoader();
        loader.setCrossOrigin = 'anonymous';
        const img = new Image();
        img.src = server_path + slot.picture.image;
        window[server_path + slot.picture.image] = img;
        var material = new THREE.MeshBasicMaterial({transparent: true, color: 0xffffff, side: THREE.DoubleSide,});//map:texture } );
        var tile = new THREE.Mesh(geometry, material);


        tile.position.set(pos_x, pos_y, pos_z);
        tile.rotation.set(Math.PI * rot_x / 180, Math.PI * rot_y / 180, Math.PI * rot_z / 180);
        tile.scale.set(scale_x, scale_y, scale_z);
        tile.material.opacity = 0.01;
        tile.addEventListener('hoverenter', (event) => {
            fadeMesh(tile, 0.01, 0.15)
        });
        tile.addEventListener('hoverleave', (event) => {
            fadeMesh(tile, 0.15, 0.01)
        });
        tile.addEventListener('click', () => this.handleInfoSpotClick(slot))
        tile.visible = false;
        panorama.add(tile)
        return tile;
    }

    createMovingPoint = (viewer, currentPanorama, destinationPanorama, destinationPoint) => {
        const infospot_img = destinationPoint.pivot.type === "room" ? 'img/infospot_room.png' : 'img/infospot_point.png';
        var textureLoader = new THREE.TextureLoader();
        textureLoader.load(infospot_img, () => {
            const infospot = new Infospot(500, infospot_img, true);
            infospot.position.set(destinationPoint.pivot.pos_x, destinationPoint.pivot.pos_y, destinationPoint.pivot.pos_z);
            infospot.addEventListener('click', () => {
                viewer.setPanorama(destinationPanorama);
                this.props.onPointChanged(destinationPoint);
            });
            currentPanorama.add(infospot)
            return infospot
        });
    }

    createRoomSelector = (panorama, point) => {
        if (point.rs_pos_x === null || point.rs_pos_y === null || point.rs_pos_z === null) {
            return null;
        }
        const selectorImg = 'img/infospot_room.png';
        var textureLoader = new THREE.TextureLoader();
        var texture = textureLoader.load(selectorImg, () => {
            const infospot = new Infospot(500, selectorImg, true);
            infospot.position.set(point.rs_pos_x, point.rs_pos_y, point.rs_pos_z);
            infospot.addEventListener('click', () => {
                this.props.onRoomSelectorClicked()
            });
            panorama.add(infospot)
            return infospot
        });
    }

    getViewer = () => {
        if (!Utils.isNEU(this.state.viewer)) {
            return this.state.viewer
        }
        const container = document.querySelector('#panolens-container')
        let viewer = new Viewer({
            container: container,
            autoHideInfospot: false,
            controlButtons: ["setting"]
        })
        const camera = viewer.getCamera();
        viewer.addUpdateCallback(()=>{
            const prevRotation = this.state.cameraRotation;
            if( camera.rotation._x !== prevRotation._x ||camera.rotation._y !== prevRotation._y ||camera.rotation._z !== prevRotation._z){
                this.setState({cameraRotation:{...camera.rotation}});
                this.props.onRotationChanged(camera.rotation)
            }
        })
        return viewer
    }

    clean = () => {
        return new Promise((resolve, reject) => {
            try {
                if (this.state.viewer) {
                    this.state.viewer.dispose()
                }
                this.setState({
                    points: [],//{...point, roomId}
                    rooms: [],//{roomId, startPanorama}
                    panoramas: [], //[{roomId:<room_id>, pointId:<point_id>, panorama: <obj>}]
                }, () => {
                    resolve()
                })
            } catch (e) {
                reject(e)
            }
        });
    }

    setUpFloor = () => {
        const {viewerConfigured} = this.state
        const room = this.props.room
        const points = _.cloneDeep(room.points).map(p => ({...p, roomId: room.id}))
        const panoramas = [...this.state.panoramas];
        let startPointId = null;
        const viewer = this.getViewer()
        if (this.state.panoramas.filter(p => p.roomId === room.id).length <= 0) {
            for (let i = 0; i < points.length; i++) {
                const point = points[i];
                this.createPanorama(viewer, room, point, panoramas) //Loads only if it was not loaded before
                if (point.is_start) {
                    startPointId = point.id
                }
            }
        }
        //Loads All markers (infoboxes, destinations and room selector)
        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            const panorama = panoramas.find(p => p.pointId === point.id && p.roomId === room.id).panorama

            for (let j = 0; j < point.destination_points.length; j++) {
                const destinationPoint = point.destination_points[j];
                const destinationPanorama = panoramas.find(p => p.pointId === destinationPoint.id && p.roomId === room.id).panorama
                this.createMovingPoint(viewer, panorama, destinationPanorama, destinationPoint)
            }

            for (let j = 0; j < point.slots.length; j++) {
                const slot = point.slots[j];
                if (slot.picture) {
                    let tile = this.createPicture(slot, panorama)
                    points[i].slots[j] = {...points[i].slots[j], tile: tile}
                }
            }
            if(this.props.roomsCount > 1)
                this.createRoomSelector(panorama, point)
        }

        let firstPoint = points.find(p => p.is_start && p.roomId === room.id)
        let firstPanorama = panoramas.find(p => p.pointId === firstPoint.id && p.roomId === room.id).panorama

        viewer.add(firstPanorama)
        if (this.props.sensor && viewerConfigured===false) {
            viewer.enableControl(1)
            this.setState({viewerConfigured:true})
        }
        viewer.tweenControlCenter(new THREE.Vector3( firstPoint.rot_x, firstPoint.rot_y, firstPoint.rot_z),0)
        let _panoramas = panoramas.filter(p => !(p.pointId === firstPoint.id) && p.roomId === room.id)
        for (let i = 0; i < _panoramas.length; i++) {
            const panorama = _panoramas[i].panorama;
            viewer.add(panorama)
        }
        if (firstPoint.rot_x != null && firstPoint.rot_y != null && firstPoint.rot_z != null) {
            viewer.tweenControlCenter(new THREE.Vector3(firstPoint.rot_x, firstPoint.rot_y, firstPoint.rot_z),0)
        }

        this.setState({points: points, panoramas: panoramas, viewer: viewer}, () => {
            this.cleanPictures(firstPoint.id, room.id)
            this.props.onPointChanged(firstPoint)
        })

    }

    render() {
        return (
            <StyledDiv classes={this.props.classes} id="panolens-container" {...this.props.style}>{this.props.children}</StyledDiv>
        )
    }
}




