import _ from 'lodash';

export const getConfigs = (otherConfigs = {}) => {
    let configs = _.merge(
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        },
        otherConfigs
    );

    return configs
}