import {Grid, IconButton, Tooltip} from "@material-ui/core";
import {MeetingRoom} from "@material-ui/icons";
import React from "react";
import styled from "styled-components";

const RoomSelectorButton = ({onClick, className}) => {
    return <Grid className={className} container justify={"flex-end"}>
        <Grid item>
            <Tooltip title="Go to another Floor">
                <IconButton color="primary" onClick={onClick}
                            style={{
                                backgroundColor: "white",
                                right: "15px",
                                boxShadow: "0px 2px 5px #0000002e"
                            }}>
                    <MeetingRoom/>
                </IconButton>
            </Tooltip>
        </Grid>
    </Grid>;
}
export const StyledRoomSelectorButton = styled(RoomSelectorButton)`
    z-index:2;
    position: absolute;
    bottom:25px;
    right: 15px;
    pointer-events:none;
    ${props => props.theme.breakpoints.up('sm')}{
        bottom:unset;
        top: 15px;    
    }
    & button{
        pointer-events:auto;
        background-color: white;
        right: 15px;
        boxShadow: 0px 2px 5px #0000002e;
    }
`