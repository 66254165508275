import React, {PureComponent} from 'react'
import DataTable from 'components/DataTable'
import _ from 'lodash'
import * as Utils from 'Utils'

import {Grid, Typography, IconButton, Paper, Divider, LinearProgress} from '@material-ui/core'
import {Delete as DeleteIcon, AddCircle as AddCircleIcon, Edit as EditIcon} from '@material-ui/icons'
import {withStyles} from '@material-ui/core/styles';

import RoomForm from 'pages/RoomsPage/RoomsForm'
import SimpleDialog from 'components/SimpleDialog'
import {RoomsProvider} from 'serviceProviders/roomProvider'
import axios from 'axios'

class RoomsPage extends PureComponent {
    state = {
        openForm: false,
        rooms: [],
        loading: false,
        selectedRoom: null,
        showConfirmation: false,
        datatable: {
            page: 0,
            rowsPerPage: 5,
            total: 0,
            order: null
        }
    }

    componentDidMount() {
        this.source = axios.CancelToken.source()
        this.refreshTable()
    }

    componentWillUnmount() {
        this.source.cancel("")
    }

    columns = () => [
        {
            label: 'Id', name: 'id', options: {
                display: "excluded"
            }
        },
        {label: 'Floor', name: 'name'},
        {
            label: 'Template', name: 'template', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.name;
                },
            }
        },
        {label: 'Pictures', name: 'slots'}

    ];

    options = () => ({
        rowsPerPageOptions: [5, 10, 20],
        onRowSelectionChange: this.onRowsSelect,
        rowsSelected: this.state.selectedRoom ? [this.state.rooms.findIndex(p => this.state.selectedRoom.id === p.id)] : []
    })

    onRowsSelect = (c, a, r) => {
        if (!this.state.loadingStudies) {
            if (r.length > 0) {
                const room = _.cloneDeep(this.state.rooms[r[0]])
                if (room) {
                    this.setState({selectedRoom: room})
                    return
                }
            }
        }
        this.setState({selectedRoom: null})
    }

    refreshTable = (page = this.state.datatable.page, rowsPerPage = this.state.datatable.rowsPerPage, order = this.state.datatable.order) => {
        this.setState({loading: true})
        RoomsProvider.all({page: page, rowsPerPage: rowsPerPage, order: order}, this.source.token).then((data) => {
            data = {...data, rooms: data.rooms.filter(room => room.template.is_selectable !== false)}
            const aux = Math.ceil(data.total / rowsPerPage)
            page = page <= aux - 1 ? page : aux === 0 ? 0 : aux - 1
            if (this.state.datatable.page !== page) {
                this.refreshTable(page, rowsPerPage, order)
            } else {
                this.setState({rooms: data.rooms,
                    datatable: {
                        ...this.state.datatable,
                        total: data.total,
                        rowsPerPage: rowsPerPage,
                        order: order,
                        page: page
                    },
                    loading: false
                });
                if (data.rooms.length === 0)
                    this.setState({selectedRoom: null})
            }

        }).catch(err => {
            if (!axios.isCancel(err)) {
                this.setState({loading: false})
            }

        })
    }

    handleFormOpened = (action) => {
        this.setState({openForm: true, selectedRoom: action === "create" ? null : this.state.selectedRoom})
    }
    handleFormClosed = () => {
        this.setState({openForm: false, selectedRoom: null}, () => {
            this.refreshTable()
        })
    }
    handleShowConfirmation = () => {
        this.setState({showConfirmation: true})
    }
    handleDelete = () => {
        if (this.state.selectedRoom) {
            RoomsProvider.del(this.state.selectedRoom.id, this.source.token).then(() => {
                this.refreshTable()
            })
        }
    }

    render() {
        const {theme} = this.props
        const {openForm, rooms, loading, datatable, selectedRoom} = this.state
        return (
            <>
                <SimpleDialog
                    open={this.state.showConfirmation}
                    title="Delete Floor"
                    text="Are you sure you want to delete this floor? This action can't be undone."
                    acceptLabel="Yes, I'm sure!"
                    cancelLabel="No"
                    showCancel={true}
                    onAccept={() => {
                        this.handleDelete();
                        this.setState({showConfirmation: false})
                    }}
                    onClose={() => {
                        this.setState({showConfirmation: false})
                    }}
                />
                <RoomForm
                    title={selectedRoom ? "Edit Floor" : "New Floor"}
                    open={openForm}
                    onClose={this.handleFormClosed}
                    room={selectedRoom}
                />
                <Paper elevation={3} style={{
                    padding: theme.spacing(2),
                    height: "100%"
                }}>
                    <Grid container alignItems="flex-end" spacing={1}>
                        <div style={{
                            width: "100%"
                        }}>
                            <Grid item xs={12} container style={{
                                width: "100%"
                            }} alignItems="center">
                                <Grid item style={{
                                    flexGrow: 1
                                }}>
                                    <Typography variante="h6">
                                        Floors
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton edge="start" color="primary"
                                                onClick={() => this.handleFormOpened("create")}
                                                aria-label="Add New Floor">
                                        <AddCircleIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={Utils.isNEU(selectedRoom)} edge="start" color="primary"
                                                onClick={() => this.handleFormOpened("edit")} aria-label="Edit Floor">
                                        <EditIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton disabled={Utils.isNEU(selectedRoom)} edge="start" color="primary"
                                                onClick={this.handleShowConfirmation} aria-label="Delete Floor">
                                        <DeleteIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            {
                                loading
                                    ? <LinearProgress/>
                                    : null
                            }
                            <DataTable
                                title="Floors"
                                elevation={0}
                                columns={this.columns()}
                                data={rooms}
                                count={datatable.total}
                                page={datatable.page}
                                rowsPerPage={datatable.rowsPerPage}
                                onChangePage={this.refreshTable}
                                onChangeRowsPerPage={this.refreshTable}
                                onColumnSortChange={this.refreshTable}
                                hideToolbar={true}
                                options={this.options()}
                            />
                        </div>
                    </Grid>
                </Paper>

            </>
        )
    }
}

export default withStyles(null, {withTheme: true})(RoomsPage)