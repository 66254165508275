import React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PanoramaHorizontalIcon from '@material-ui/icons/PanoramaHorizontal';
import styled from 'styled-components'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const ActiveListItem = styled(ListItem)`
    border-left: 5px solid ${props => props.theme.palette.primary.main};
    padding-left: 8px;
    margin-left: 3px;
    
    color:${props => props.theme.palette.primary.main};
    & .MuiListItemIcon-root{
        color:${props => props.theme.palette.primary.main};
    }
`

function CustomAppBar(props) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const DrawerContent = (props) => {
        return (
            <div>
                <div className={classes.toolbar} />
                {props.groups.map((group, i) => (
                    <div key={"g" + i} style={{ overflowX: "hidden" }}>
                        <Divider />
                        <List>
                            {
                                group.map((item, j) => {
                                    return props.location.pathname !== item.path ?
                                        (
                                            <ListItem button key={"i" + item.id} onClick={() => props.onItemClicked(i, item.id)}>
                                                {
                                                    item.icon
                                                        ? <ListItemIcon>{React.createElement(item.icon)}</ListItemIcon>
                                                        : null
                                                }
                                                <ListItemText primary={item.label} />
                                            </ListItem>
                                        )
                                        :
                                        (
                                        <ActiveListItem button key={"i" + item.id} onClick={() => props.onItemClicked(i, item.id)}>
                                            {
                                                item.icon
                                                    ? <ListItemIcon>{React.createElement(item.icon)}</ListItemIcon>
                                                    : null
                                            }
                                            <ListItemText primary={item.label} />
                            </ActiveListItem>
                                        )

                                    }
                                )
                            }
                        </List>
                    </div>
                ))}
            </div>
        )
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    {React.cloneElement(props.icon, { style: { marginRight: "10px" } })}
                    <Typography variant="h6" noWrap>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <DrawerContent location={props.location} groups={props.menuItems} onItemClicked={props.onMenuItemClicked} />
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >

                        <DrawerContent location={props.location} groups={props.menuItems} onItemClicked={props.onMenuItemClicked} />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}

CustomAppBar.defaultProps = {
    icon: (<></>),
    menuItems: [],
    onMenuItemClicked: (id) => {}
};

export default withRouter(CustomAppBar);