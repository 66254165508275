import printError from 'error'
import axios from 'axios'
import { getConfigs } from 'axiosConfig'
import * as Utils from 'Utils'

const apiPath = process.env.REACT_APP_API;

export const PicturesProvider = {
    all: (datatable, cancelToken) => {
        return axios.get(apiPath + "pictures", {
            ...getConfigs(),
            cancelToken: cancelToken,
            params: { ...datatable, filters: JSON.stringify(datatable.filters), order: JSON.stringify(datatable.order) }
        }).then(res => {

            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },
    get: (pictureId, cancelToken) => {
        return axios.get(apiPath + "pictures/" + pictureId, {
            ...getConfigs(), cancelToken: cancelToken,
        }).then(res => {

            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },
    post:(picture, cancelToken) => {
        var formData = new FormData();
        formData.append("image", picture.image)
        picture = { title: picture.title, info: picture.info, link: picture.link, linkTitle:picture.linkTitle, size: picture.size }
        formData.append("data", JSON.stringify(picture))

        return axios.post(apiPath + "pictures", formData, {
            ...getConfigs(), cancelToken: cancelToken,
        }).then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },
    put:(picture, cancelToken) => {
        var formData = new FormData();
        if (!Utils.isString(picture.image))
            formData.append("image", picture.image)
        picture = { id : picture.id, title: picture.title, info: picture.info, link: picture.link, linkTitle:picture.linkTitle, size: picture.size }
        formData.append("data", JSON.stringify(picture))
        formData.append('_method', 'PUT');
        return axios.post(apiPath + "pictures/" + picture.id, formData, {
            ...getConfigs({
                headers: {
                    'Cache-Control': 'no-cache',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data'
                }
            }), cancelToken: cancelToken,
        }).then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {
            printError(err)
            return Promise.reject(err)
        })
    },
    del:(pictureId, cancelToken) => {
        return axios.delete(apiPath + "pictures/" + pictureId, {
            ...getConfigs(), cancelToken: cancelToken,
        }).then(res => {
            return Promise.resolve(res.data)
        }).catch(err => {

            printError(err)
            return Promise.reject(err)
        })
    }
}

