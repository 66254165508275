import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { pages_routes } from './routes'
import { Route, Switch, BrowserRouter as Router, } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core'
import { theme } from 'theme'


function App() {
  return (
    <MUIThemeProvider theme={theme}>

      <ThemeProvider theme={theme}>

        <Router>
          <Switch>
            {pages_routes.map(route => (
              <Route key={route.path} path={route.path} exact={route.exact}>
                <route.component {...route.props} />
              </Route>
            ))}
          </Switch>
        </Router>
      </ThemeProvider>
    </MUIThemeProvider>
  );
}

export default App;
