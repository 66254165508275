import PropTypes from "prop-types";
import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@material-ui/core'

export default function SimpleDialog({
    open,
    text,
    title,
    priority,
    onClose,
    onAccept,
    showCancel,
    showAccept,
    cancelLabel,
    acceptLabel
}) {
    return <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {
                showCancel ?
                <Button onClick={onClose} color="primary" autoFocus>
                        {cancelLabel}
                </Button>
                : null
            }
            {
                showAccept ?
                    <Button onClick={onAccept} color="primary" autoFocus>
                        {acceptLabel}
                    </Button>
                    : null
            }
        </DialogActions>
    </Dialog>;
}

SimpleDialog.propTypes = {
    onAccept: PropTypes.func.isRequired,    
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    priority: PropTypes.string,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    showCancel: PropTypes.bool,
    acceptLabel: PropTypes.string,
    cancelLabel: PropTypes.string
}
SimpleDialog.defaultProps = {
    onAccept: () => { },
    onClose: () => { },
    open: false,
    priority: "info",
    text: "Operación Fallida: Error desconocido.",
    title: "¡Ha ocurrido un error!",
    showCancel: false,
    showAccept: true,
    acceptLabel: "Aceptar",
    cancelLabel: "Cancelar"
}
