import React, { Component } from 'react'

import { Grid, TextField,  Typography, Tooltip } from '@material-ui/core'
import {CloudUpload as CloudUploadIcon, HelpOutline} from '@material-ui/icons';
import FullScreenDialog from 'components/FullScreenDialog'
import SimpleDialog from 'components/SimpleDialog'
import {PicturesProvider} from 'serviceProviders/pictureProvider'
import UploadFile from 'components/UploadFile';
import * as Utils from 'Utils';
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";

const public_path = process.env.REACT_APP_PUBLIC;
const server_path = process.env.REACT_APP_SERVER;

const emptyPicture = {
    title: null,
    date: null,
    size: null,
    info: null,
    link: null,
    linkTitle:null,
    image: null
}

export default class PicturesForm extends Component {
    state = {
        picture: emptyPicture,
        submitting:false,
        errors: null,
        showErrors:false
    }

    componentDidUpdate(oldProps, oldState) { 
        if (!oldProps.open && this.props.open) { 
            if (this.props.picture) { 
                this.setState({picture:this.props.picture})
            }
        }
    }

    options = () => {
        return {
            showClose: true,
            actions: [
                {
                    label: "SAVE",
                    disabled: this.state.submitting,
                    onClick: () => {
                        this.setState({ submitting: true })
                        if (this.validate()) { 

                            if (!this.state.picture.id) { 
                                PicturesProvider.post(this.state.picture)
                                .then(res => { 
                                    this.handleClose()
                                }).catch(error => {
                                    if (error && error.data) { 
                                        this.setState({errors:error.data})
                                    }
                                }).finally(() => {
                                    this.setState({ submitting: false })
                                })
                        }
                        else{
                            PicturesProvider.put(this.state.picture)
                            .then(res => {
                                    this.handleClose()
                                }).catch(error => {
                                    if (error && error.data) {
                                        this.setState({ errors: error.data })
                                    }
                                }).finally(() => {
                                    this.setState({ submitting: false })
                                })
                            }
                        }
                        else {
                            this.setState({ submitting: false })
                        }
                    }
                }
            ]
        }
    }
    validate = () => {
        const picture = this.state.picture
        let errors = null

        if (Utils.isNEU(picture.title)) {
            errors = { ...errors, title: "The title field is required." }
        }
        if (Utils.isNEU(picture.size)) {
            errors = { ...errors, size: "The size field is required." }
        }

        if (picture.image === null || picture.image === undefined) {
            errors = { ...errors, image: "The picture must have an image uploaded." }
        }

        if (!Utils.isNEU(picture.link)) {
            if(Utils.isNEU(picture.linkTitle)){
                errors = { ...errors, linkTitle: "The link must have a title to display."}
            }
        }


            this.setState({ errors: errors, showErrors: errors !== null })
        return errors === null
    }

    handleInputChange = (e) => {
        this.setState({
            picture: { ...this.state.picture, [e.target.name]: e.target.name === "image" ? e.target.files[0]:e.target.value }
        })
    }

    handleClose = () => { 
        this.setState({ picture: emptyPicture, errors:null })
        this.props.onClose()
    }

    render() {
        const { open, title } = this.props
        const { picture, errors, showErrors, submitting} = this.state
        return (
            <>
                <SimpleDialog
                    open={showErrors && !Utils.isNEU(errors) && (!Utils.isNEU(errors.image))}
                    title="Error!"
                    text={(errors && errors.image) || ""}
                    showCancel={false}
                    showAccept={true}
                    acceptLabel="OK"
                    onAccept={() => this.setState({ showErrors: false })}
                />
                <FullScreenDialog
                    open={open}
                    title={title}
                    onClose={this.handleClose}
                    options={this.options()}
                >
                {
                    submitting?
                        <CenteredCircularProgress backdropped={true} label="Saving Picture, please wait..." />
                        :null
                }
                <Grid container  spacing={5}>
                    <Grid item xs={12} md={7} style={{textAlign:'center'}}>
                        <img src={picture.image ? Utils.isString(picture.image) ? server_path + picture.image : URL.createObjectURL(picture.image) : public_path + "/img/placeholder.png"}></img>
                    </Grid>
                    <Grid container direction="column" item xs={12} md={5} spacing={3}>
                        <Grid item>
                            <Tooltip title="Picture Title - this field is required">
                                <TextField
                                    error={(errors && errors.title) || false}
                                    helperText={(errors && errors.title || "")}
                                    fullWidth={true}
                                    label="Title"
                                    name="title"
                                    value={picture.title || ""}
                                    onChange={this.handleInputChange}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Picture Sizes - this field is required">
                            <TextField
                                error={(errors && errors.size) || false}
                                helperText={(errors && errors.size|| "")}
                                multiline
                                rows={5}
                                rowsMax={5}
                                fullWidth={true}
                                label="Sizes"
                                name="size"
                                value={picture.size || ""}
                                onChange={this.handleInputChange}
                            />
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Any Extra Information about the picture">
                            <TextField
                                fullWidth={true}
                                label="Information"
                                name="info"
                                value={picture.info || ""}
                                multiline
                                rows={5}
                                rowsMax={5}
                                onChange={this.handleInputChange}
                            />
                            </Tooltip>
                        </Grid>
                        <Grid container item spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Tooltip title="Text that will be shown instead of the raw link. E.g. Video">
                                <TextField
                                    fullWidth={true}
                                    label="Link Title"
                                    name="linkTitle"
                                    value={picture.linkTitle || ""}
                                    onChange={this.handleInputChange}
                                />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Tooltip title="Url of the link">
                                <TextField
                                    fullWidth={true}
                                    label="Link"
                                    name="link"
                                    value={picture.link || ""}
                                    onChange={this.handleInputChange}
                                />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item style={{ textAlign: "center", marginTop: "20px" }}>
                            <UploadFile
                                name="image"
                                accept=".jpg,.png"
                                multiple={false}
                                count = {picture.image?1:0}
                                onFilesSelected={this.handleInputChange}
                            >
                                <CloudUploadIcon style={{ marginRight: "10px" }} /> Upload Image
                            </UploadFile>                            
                        </Grid>
                    </Grid>
                </Grid>
                </FullScreenDialog>
            </>
        )
    }
}