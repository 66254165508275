import MUIDataTable from 'mui-datatables';
import styled from 'styled-components';
export const StyledTable = styled(MUIDataTable)`    
    
    & .MuiTableCell-root{
        text-align:center;
        span{
            justify-content:center;        
        }
   }
    
    & > .MuiToolbar-root{
        align-items:flex-start;
        padding-top:10px;
        ${props => props.hideToolbar || (props.hideTitle && props.hideTools) ? "display:none;" : ""}
    }
    & > .MuiToolbar-root > div{
        flex:unset;        
    }
    & > .MuiToolbar-root>div:first-child{
        flex:unset;
        flex-grow:1;
        ${props => props.hideTitle ? "display:none;" : ""}
    }
    & > .MuiToolbar-root>div:nth-child(2){
        ${props => props.hideTools ? "display:none;" : ""}
    }

    & > div:nth-child(3){
        max-height: ${props => props.maxHeight}!important;
        overflow-y:auto;
    }
    
    ${props => props.theme.breakpoints.down('sm')}{

        & .MuiTableCell-body:not(.MuiTableCell-paddingCheckbox) {
            padding: 0;
            white-space: unset;
            height: unset;
            vertical-align: baseline;
        }
    }
    
`;
