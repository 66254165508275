import {FormControl} from '@material-ui/core';
import styled from 'styled-components';
import * as Utils from 'Utils';
export const StyledFormControl = styled(FormControl)`
    min-width: ${props => props.minWidth || "195px"};
    max-width: ${props => props.maxWidth || "unset"};
    & .MuiFormLabel-root {
        color: ${props => props.error ? "red" : "#0000008a"}
    }
    & .MuiInput-underline:before{
        ${props => props.error ? "border-bottom: 2px solid red" : ""}        
    }
    & .MuiInput-underline:after{
        ${props => props.error ? "border-bottom: 2px solid red" : ""}
    }
    & .MuiInput-underline:hover:not(.Mui-disabled):before{
        ${props => props.error ? "border-bottom: 2px solid red" : ""}
    }
`;
