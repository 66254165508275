import _ from 'lodash'
import {isIOS} from 'react-device-detect';

export const isNU=(obj)=>{
    return obj === null || obj === undefined;
}
export const isNEU = (obj) => { 
    if (Array.isArray(obj)) {
        return _.isNull(obj) || _.isEmpty(obj) || _.isUndefined(obj)
    } else if (typeof obj === 'string' || obj instanceof String) {
        return obj === null || obj === undefined || obj.trim() === "";
    }
    else {
        return _.isNull(obj) || _.isEmpty(obj) || _.isUndefined(obj)
    }
}

export const isString = (obj) => { 
    return typeof obj === 'string' || obj instanceof String
}

export const isIOS13 = ()=>{
    return isIOS && typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function'
}
